import moment from 'moment'
import { store } from '../../store/index'
import router from '../../router'
import { operationNotification } from './notification'

export function beginDate(term) {
  if (term) {
    return formatDate(term.begin_date.toDate())
  }
}

export function endDate(term) {
  if (term) {
    return formatDate(term.end_date.toDate())
  }
}

export function monthsInTerm(term) {
  const monthsInYear = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  }
  if (term) {
    let months = []
    let beginMonth = new Date(term.begin_date.seconds * 1000).getMonth() + 1
    let endMonth = new Date(term.end_date.seconds * 1000).getMonth() + 1

    if (endMonth < beginMonth) {
      endMonth = 12
    }

    for (let m = beginMonth; m <= endMonth; m++) {
      months.push(monthsInYear[m])
      if (m === 12) {
        beginMonth = 1
        endMonth = term.end_date.toDate().getMonth() + 1
        for (let m = beginMonth; m <= endMonth; m++) {
          months.push(monthsInYear[m])
        }
      }
    }
    return months
  }
}

export function schoolDaysInTermDuration(term) {
  const schoolDays = []
  const beginDate = term.begin_date.toDate()
  const endDate = term.end_date.toDate()
  let beginMonth = term.begin_date.toDate().getMonth() + 1
  let endMonth = term.end_date.toDate().getMonth() + 1
  let months = monthsInTerm(term)
  let firstDay
  let lastDay
  let year

  if (endMonth < beginMonth) {
    endMonth = 12
  }

  for (let m = beginMonth; m <= endMonth; m++) {
    year = beginDate.getFullYear()
    if (m === months[0]) {
      firstDay = beginDate.getDate()
      lastDay = new Date(year, m, 0).getDate()
    } else if (m === months[months.length - 1]) {
      firstDay = 1
      lastDay = endDate.getDate()
      year = endDate.getFullYear()
    } else {
      firstDay = 1
      lastDay = new Date(year, m, 0).getDate()
    }
    for (let i = firstDay; i <= lastDay; i++) {
      let date = new Date(year, m - 1, i)
      if ([1, 2, 3, 4, 5].includes(moment(date.toISOString()).day())) {
        schoolDays.push(date.toDateString())
      }
    }
    if (m === 12) {
      beginMonth = 1
      endMonth = term.end_date.toDate().getMonth() + 1
      year = endDate.getFullYear()
      firstDay = 1
      for (let m = beginMonth; m <= endMonth; m++) {
        if (m === months[months.length - 1]) {
          lastDay = endDate.getDate()
        } else {
          lastDay = new Date(year, m, 0).getDate()
        }
        for (let i = firstDay; i <= lastDay; i++) {
          let date = new Date(year, m - 1, i)
          if ([1, 2, 3, 4, 5].includes(moment(date.toISOString()).day())) {
            schoolDays.push(date.toDateString())
          }
        }
      }
    }
  }
  return schoolDays
}

export function schoolDaysInMonth(term, month) {
  const months = monthsInTerm(term)
  const beginDate = term.begin_date.toDate()
  const endDate = term.end_date.toDate()
  const schoolDays = []
  let firstDay
  let lastDay

  if (months.includes(month)) {
    if (month === months.shift()) {
      firstDay = beginDate.getDate()
      lastDay = new Date(beginDate.getFullYear(), month, 0).getDate()
    } else if (month === months.pop()) {
      firstDay = 1
      lastDay = endDate.getDate()
    } else {
      firstDay = 1
      lastDay = new Date(beginDate.getFullYear(), month, 0).getDate()
    }

    for (let i = firstDay; i <= lastDay; i++) {
      let date = new Date(beginDate.getFullYear(), month, i)
      let _moment = moment(date.toISOString())
      if ([1, 2, 3, 4, 5].includes(_moment.day())) {
        schoolDays.push(date.toDateString())
      }
    }
  } else {
    schoolDays.push('The month does not fall within the given month')
  }
  return schoolDays
}

export function nonSchoolDays() {
  // Non school days
}

// export function holidaysInMonth () {

// }

// export function offDaysInMonth () {

// }

export function isTermExpired() {
  const isExpired = store.getters.getTermExpired == 'true'
  if (isExpired) {
    operationNotification('Term has expired. Create a new Term.', 'warning')
    router.push('/')
    return true
  }
}

export function sessionTitle(term) {
  return (
    term.session_time +
    ' ' +
    term.session_year +
    ' - ' +
    (parseInt(term.session_year) + 1)
  )
}

export function formatDate(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

// School Year
export function schoolYear(sessionTime) {
  let year = new Date().getFullYear()
  if (!sessionTime) return
  return sessionTime === 'First Term' ? year : year - 1
}
