<template>
  <div id="term" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <form @submit.prevent="submit">
          <b-field label="Session Time">
            <b-select
              id="session-time"
              v-model="term.sessionTime"
              name="session time"
              placeholder="Select a option"
              expanded
            >
              <option
                v-for="session_time in session_times"
                :key="session_time.value"
                :value="session_time.value"
              >
                {{ session_time.text }}
              </option>
            </b-select>
          </b-field>
          <div class="field">
            <label class="label">Session Year</label>
            <div class="control">
              <input
                class="input"
                type="text"
                disabled
                v-model="term.sessionYear"
              />
            </div>
          </div>
          <b-field label="Begin Date">
            <b-datepicker
              icon="calendar-day"
              v-model="term.beginDate"
              :date-formatter="(date) => date.toDateString()"
            ></b-datepicker>
          </b-field>
          <b-field label="End Date">
            <b-datepicker
              icon="calendar-day"
              v-model="term.endDate"
              :date-formatter="(date) => date.toDateString()"
            ></b-datepicker>
          </b-field>
          <div class="field">
            <label class="label">Open Days</label>
            <div class="control">
              <input class="input" type="text" v-model="term.openDays" />
            </div>
          </div>
          <b-field label="Next Term Begins">
            <b-datepicker
              placeholder="Click to select..."
              icon="calendar-day"
              v-model="term.nextTermBegins"
              :date-formatter="(date) => date.toDateString()"
            ></b-datepicker>
          </b-field>
          <button
            type="submit"
            class="button btn-120 is-primary is-capitalized is-pulled-right"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { schoolYear } from '@/assets/js/term.js'
import StoreManager from './../../store/store_manager'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'term',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Term',
      skipQuery: true,
      term: {
        id: null,
        sessionTime: null,
        sessionYear: '',
        beginDate: new Date(),
        endDate: new Date(),
        openDays: '0',
        nextTermBegins: new Date(),
      },
      session_times: [
        { value: 'First Term', text: 'First Term' },
        { value: 'Second Term', text: 'Second Term' },
        { value: 'Third Term', text: 'Third Term' },
      ],
    }
  },
  apollo: {
    term: {
      query: gql`
        query termId($id: ID!) {
          term(id: $id) {
            id
            sessionTime
            sessionYear
            beginDate
            endDate
            openDays
            nextTermBegins
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    'term.sessionTime'(newValue) {
      if (!newValue) return
      if (!this.term.id) {
        this.term.sessionYear = schoolYear(newValue).toString()
      }
    },
    'term.beginDate'(newValue) {
      if (!newValue) return
      if (typeof newValue === 'string') {
        console.log(newValue)
        this.term.beginDate = new Date(newValue)
      }
    },
    'term.endDate'(newValue) {
      if (!newValue) return
      if (typeof newValue === 'string') {
        this.term.endDate = new Date(newValue)
      }
    },
    'term.nextTermBegins'(newValue) {
      if (!newValue) return
      if (typeof newValue === 'string') {
        this.term.nextTermBegins = new Date(newValue)
      }
    },
  },
  methods: {
    submit() {
      console.log(this.term)
      const sessionTime = this.term.sessionTime
      const sessionYear = this.term.sessionYear
      const beginDate = this.term.beginDate.toDateString()
      const endDate = this.term.endDate.toDateString()
      const openDays = this.term.openDays
      const nextTermBegins = this.term.nextTermBegins
        ? this.term.nextTermBegins.toDateString()
        : new Date().toDateString()
      if (this.$route.params.id === 'new') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createTermQuery(
                $school_id: Int!
                $session_time: String!
                $session_year: String!
                $begin_date: String!
                $end_date: String!
                $open_days: String
                $next_term_begins: String!
              ) {
                createTerm(
                  input: {
                    schoolId: $school_id
                    sessionTime: $session_time
                    sessionYear: $session_year
                    beginDate: $begin_date
                    endDate: $end_date
                    openDays: $open_days
                    nextTermBegins: $next_term_begins
                  }
                ) {
                  term {
                    id
                    schoolId
                    sessionType
                    sessionTime
                    sessionYear
                    beginDate
                    endDate
                    openDays
                    nextTermBegins
                    ptaConference
                    sessionTitle
                    expired
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              school_id: this.schoolId,
              session_time: sessionTime,
              session_year: sessionYear,
              begin_date: beginDate,
              end_date: endDate,
              open_days: openDays,
              next_term_begins: nextTermBegins,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createTerm.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/terms`)

              const { __typename, ...rest } = response.data.createTerm.term

              const term = {
                id: rest.id,
                school_id: rest.schoolId,
                session_type: rest.sessionType,
                session_time: rest.sessionTime,
                session_year: rest.schoolYear,
                begin_date: rest.beginDate,
                end_date: rest.endDate,
                open_days: rest.openDays,
                next_term_begins: rest.nextTermBegins,
                pta_conference: rest.ptaConference,
                session_title: rest.sessionTitle,
                expired: rest.expired,
              }

              StoreManager.save('term', term)
              this.$store.commit('setTerm', term)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateTerm(
                $id: Int!
                $session_time: String!
                $session_year: String!
                $begin_date: String!
                $end_date: String!
                $open_days: String
                $next_term_begins: String!
              ) {
                updateTerm(
                  input: {
                    id: $id
                    sessionTime: $session_time
                    sessionYear: $session_year
                    beginDate: $begin_date
                    endDate: $end_date
                    openDays: $open_days
                    nextTermBegins: $next_term_begins
                  }
                ) {
                  term {
                    id
                    sessionTime
                    sessionYear
                    beginDate
                    endDate
                    nextTermBegins
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.term.id),
              session_time: sessionTime,
              session_year: sessionYear,
              begin_date: beginDate,
              end_date: endDate,
              open_days: openDays,
              next_term_begins: nextTermBegins,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateTerm.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(`/school/${this.schoolId}/terms`)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      { name: 'Terms', route: `/school/${this.schoolId}/terms` },
    ])
  },
}
</script>

<style lang="scss" scoped></style>
style
